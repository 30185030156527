import { useLocation } from "react-router";

export default function NotFound() {
  const { pathname } = useLocation();
  return (
    <>
      <h1>Not Found</h1>
      <p>
        There's no page at <kbd>{pathname}</kbd>
      </p>
    </>
  );
}
