// @generated by protoc-gen-es v2.0.0 with parameter "target=js+dts,import_extension=.js"
// @generated from file dbmanagement/common.proto (package stately.dbmanagement, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_google_protobuf_type } from "@bufbuild/protobuf/wkt";

/**
 * Describes the file dbmanagement/common.proto.
 */
export const file_dbmanagement_common =
  /*@__PURE__*/
  fileDesc(
    "ChlkYm1hbmFnZW1lbnQvY29tbW9uLnByb3RvEhRzdGF0ZWx5LmRibWFuYWdlbWVudCJjCglGaWVsZFBhdGgSEgoKcHJvdG9fcGF0aBgBIAMoBRIvCgpwcm90b19raW5kGAIgASgOMhsuZ29vZ2xlLnByb3RvYnVmLkZpZWxkLktpbmQSEQoJanNvbl9wYXRoGAMgAygJIpoBChVOYW1lZEZpZWxkUGF0aFNlZ21lbnQSEgoKcHJvdG9fcGF0aBgBIAMoBRIvCgpwcm90b19raW5kGAIgASgOMhsuZ29vZ2xlLnByb3RvYnVmLkZpZWxkLktpbmQSEQoJanNvbl9wYXRoGAMgAygJEhEKCWl0ZW1fdHlwZRgEIAEoCRIWCg5pdGVtX3R5cGVfb25seRgFIAEoCEKYAQoYY29tLnN0YXRlbHkuZGJtYW5hZ2VtZW50QgtDb21tb25Qcm90b1ABogIDU0RYqgIUU3RhdGVseS5EYm1hbmFnZW1lbnTKAhRTdGF0ZWx5XERibWFuYWdlbWVudOICIFN0YXRlbHlcRGJtYW5hZ2VtZW50XEdQQk1ldGFkYXRh6gIVU3RhdGVseTo6RGJtYW5hZ2VtZW50YgZwcm90bzM",
    [file_google_protobuf_type],
  );

/**
 * Describes the message stately.dbmanagement.FieldPath.
 * Use `create(FieldPathSchema)` to create a new message.
 */
export const FieldPathSchema = /*@__PURE__*/ messageDesc(file_dbmanagement_common, 0);

/**
 * Describes the message stately.dbmanagement.NamedFieldPathSegment.
 * Use `create(NamedFieldPathSegmentSchema)` to create a new message.
 */
export const NamedFieldPathSegmentSchema = /*@__PURE__*/ messageDesc(file_dbmanagement_common, 1);
