import { useMemo } from "react";
import { createUserClient } from ".";
import { useStatelyClient } from "../client/useStatelyClient";

// useUserClient returns a client which is pre-configured to talk to the stately user backend service.
//
export const useUserClient = () => {
  const client = useStatelyClient();
  return useMemo(() => createUserClient(client), [client]);
};
