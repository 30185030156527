import { Code } from "@connectrpc/connect";
import { StatelyError } from "services/client/errors";
import { UserClient, enroll, whoami } from "services/user";

/**
 * Load user information, enrolling them in Stately's system if necessary.
 */
export async function loadUser(userClient: UserClient, enrolled = false) {
  try {
    const result = await whoami(userClient);
    if (result.enrollmentTime > 0n) {
      // They're all good to go
      return result;
    }
  } catch (err) {
    if ($FLAVOR === "local" && err instanceof StatelyError && err.code === Code.Unknown) {
      throw new Error(
        "Couldn't connect to the API. It may not be running locally - try running `just start-backend`.",
      );
    }
    // This error would mean the user doesn't exist and we need to call enroll.
    // Otherwise, rethrow.
    if (!(err instanceof StatelyError && err.code === Code.NotFound)) {
      throw err;
    }
  }
  if (enrolled) {
    throw new Error("Unable to load user info");
  }

  // Set up the user in our system, create default resources, etc.
  await enroll(userClient);

  // Reload so we get all the info. Requires strong consistency :-)
  return loadUser(userClient, true);
}
