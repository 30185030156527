import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";

export class FatalAuthTokenError extends Error {
  constructor(message: string, cause?: Error) {
    super(message);
    this.name = "FatalAuthTokenError";
    this.cause = cause;
  }
}

// `useAuthToken` returns a function: `getToken()` that can be used to
// silently retrieve an auth token for the currently logged in user.
// This hook handles setting the audience and scope so the caller doesn't
// need to worry about it.
//
// @example
// const getToken = useAuthToken()
// const token = await getToken()
export const useAuthToken = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useCallback(async () => {
    // Check for a test user token first, otherwise use Auth0's workflow to silently fetch a token
    const testUserAuthToken = localStorage.getItem("testUserAuthToken");
    if (testUserAuthToken) {
      return testUserAuthToken;
    }
    try {
      return await getAccessTokenSilently();
    } catch (e) {
      if (
        e instanceof Error &&
        (e.message.includes("Unknown or invalid refresh token") ||
          e.message.includes("Missing Refresh Token"))
      ) {
        throw new FatalAuthTokenError(e.message, e);
      }
      throw e;
    }
  }, [getAccessTokenSilently]);
};
