import { Theme } from "@radix-ui/themes";
import { withProfiler } from "@sentry/react";
import mermaid from "mermaid";
import { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { useMediaQuery } from "utils/media-query";
import { Auth0ProviderWithNavigate } from "../state/auth/Auth0ProviderWithNavigate";
import App from "./App";
import GATracker from "./shell/GATracker";

// Wrap App with Sentry profiling
const ProfiledApp = withProfiler(App);

/**
 * The Root component is the root of our application where all the providers get set up.
 */
export default function Root() {
  // This theme provider nonsense is because Radix is trying to do some stuff for SSR that we don't care about.
  const darkTheme = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = darkTheme ? "dark" : "light";
  useEffect(() => {
    const root = document.documentElement;
    root.classList.remove("light", "dark");
    root.style.colorScheme = theme;
    root.classList.add(theme);

    mermaid.initialize({
      startOnLoad: false,
      darkMode: theme === "dark",
      theme: theme === "dark" ? "dark" : "default",
    });
  }, [theme]);
  return (
    <Theme accentColor="ruby" grayColor="mauve">
      <Router future={{ v7_startTransition: true }}>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <Auth0ProviderWithNavigate>
            <GATracker />
            <ProfiledApp />
          </Auth0ProviderWithNavigate>
        </QueryParamProvider>
      </Router>
    </Theme>
  );
}
