// organize-imports-ignore
// We want our main CSS to load before all other CSS.
import "./global-styles/global.css";
import "services/analytics/ga";
import "./utils/sentry";
import { infoLog, warnLog } from "utils/log";
import { createRoot } from "react-dom/client";
import Root from "ui/Root";

infoLog(
  "app",
  `Stately Cloud Console ${$BUILD_ID} - Please report any errors to https://support.stately.cloud`,
);

if ($FLAVOR === "local" && !window.__GRPCWEB_DEVTOOLS__) {
  warnLog(
    "tools",
    "Install the gRPC-Web DevTools browser extension for a better debugging experience: https://chrome.google.com/webstore/detail/grpc-web-developer-tools/kanmilmfkjnoladbbamlclhccicldjaj",
  );
}
if ($FLAVOR === "local" && !window.__REDUX_DEVTOOLS_EXTENSION__) {
  warnLog(
    "tools",
    "Install the Redux DevTools browser extension for a better debugging experience: https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd",
  );
}

const root = createRoot(document.getElementById("app")!);
root.render(<Root />);
