// @generated by protoc-gen-es v2.0.0 with parameter "target=js+dts,import_extension=.js"
// @generated from file schemaservice/service.proto (package stately.schemaservice, syntax proto3)
/* eslint-disable */

import { fileDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import { file_schemaservice_delete } from "./delete_pb.js";
import { file_schemaservice_get } from "./get_pb.js";
import { file_schemaservice_list_audit_log } from "./list_audit_log_pb.js";
import { file_schemaservice_put } from "./put_pb.js";
import { file_schemaservice_validate } from "./validate_pb.js";

/**
 * Describes the file schemaservice/service.proto.
 */
export const file_schemaservice_service =
  /*@__PURE__*/
  fileDesc(
    "ChtzY2hlbWFzZXJ2aWNlL3NlcnZpY2UucHJvdG8SFXN0YXRlbHkuc2NoZW1hc2VydmljZTLhAwoNU2NoZW1hU2VydmljZRJRCgNQdXQSIS5zdGF0ZWx5LnNjaGVtYXNlcnZpY2UuUHV0UmVxdWVzdBoiLnN0YXRlbHkuc2NoZW1hc2VydmljZS5QdXRSZXNwb25zZSIDkAICEmAKCFZhbGlkYXRlEiYuc3RhdGVseS5zY2hlbWFzZXJ2aWNlLlZhbGlkYXRlUmVxdWVzdBonLnN0YXRlbHkuc2NoZW1hc2VydmljZS5WYWxpZGF0ZVJlc3BvbnNlIgOQAgESUQoDR2V0EiEuc3RhdGVseS5zY2hlbWFzZXJ2aWNlLkdldFJlcXVlc3QaIi5zdGF0ZWx5LnNjaGVtYXNlcnZpY2UuR2V0UmVzcG9uc2UiA5ACARJaCgZEZWxldGUSJC5zdGF0ZWx5LnNjaGVtYXNlcnZpY2UuRGVsZXRlUmVxdWVzdBolLnN0YXRlbHkuc2NoZW1hc2VydmljZS5EZWxldGVSZXNwb25zZSIDkAICEmwKDExpc3RBdWRpdExvZxIqLnN0YXRlbHkuc2NoZW1hc2VydmljZS5MaXN0QXVkaXRMb2dSZXF1ZXN0Gisuc3RhdGVseS5zY2hlbWFzZXJ2aWNlLkxpc3RBdWRpdExvZ1Jlc3BvbnNlIgOQAgFCngEKGWNvbS5zdGF0ZWx5LnNjaGVtYXNlcnZpY2VCDFNlcnZpY2VQcm90b1ABogIDU1NYqgIVU3RhdGVseS5TY2hlbWFzZXJ2aWNlygIVU3RhdGVseVxTY2hlbWFzZXJ2aWNl4gIhU3RhdGVseVxTY2hlbWFzZXJ2aWNlXEdQQk1ldGFkYXRh6gIWU3RhdGVseTo6U2NoZW1hc2VydmljZWIGcHJvdG8z",
    [
      file_schemaservice_delete,
      file_schemaservice_get,
      file_schemaservice_list_audit_log,
      file_schemaservice_put,
      file_schemaservice_validate,
    ],
  );

/**
 * Schema is the service for creating, updating, and retrieving a Schema for a
 * StatelyDB Store. Each StatelyDB Store has a single Schema associated with it
 * that defines the shape of items within that Store. StatelyDB supports
 * progressive Schemas so not all Item Types or Item properties must be defined
 * in a Schema up front. Schema management is performed using the Stately CLI.
 *
 * @generated from service stately.schemaservice.SchemaService
 */
export const SchemaService = /*@__PURE__*/ serviceDesc(file_schemaservice_service, 0);
