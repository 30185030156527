// @generated by protoc-gen-es v2.0.0 with parameter "target=js+dts,import_extension=.js"
// @generated from file user/get_caller_identity.proto (package stately.user, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file user/get_caller_identity.proto.
 */
export const file_user_get_caller_identity =
  /*@__PURE__*/
  fileDesc(
    "Ch51c2VyL2dldF9jYWxsZXJfaWRlbnRpdHkucHJvdG8SDHN0YXRlbHkudXNlciIaChhHZXRDYWxsZXJJZGVudGl0eVJlcXVlc3QiLAoZR2V0Q2FsbGVySWRlbnRpdHlSZXNwb25zZRIPCgd1c2VyX2lkGAEgASgEQnsKEGNvbS5zdGF0ZWx5LnVzZXJCFkdldENhbGxlcklkZW50aXR5UHJvdG9QAaICA1NVWKoCDFN0YXRlbHkuVXNlcsoCDFN0YXRlbHlcVXNlcuICGFN0YXRlbHlcVXNlclxHUEJNZXRhZGF0YeoCDVN0YXRlbHk6OlVzZXJiBnByb3RvMw",
  );

/**
 * Describes the message stately.user.GetCallerIdentityRequest.
 * Use `create(GetCallerIdentityRequestSchema)` to create a new message.
 */
export const GetCallerIdentityRequestSchema =
  /*@__PURE__*/
  messageDesc(file_user_get_caller_identity, 0);

/**
 * Describes the message stately.user.GetCallerIdentityResponse.
 * Use `create(GetCallerIdentityResponseSchema)` to create a new message.
 */
export const GetCallerIdentityResponseSchema =
  /*@__PURE__*/
  messageDesc(file_user_get_caller_identity, 1);
