import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@radix-ui/themes";

export default function LoginButton() {
  const { loginWithRedirect } = useAuth0();
  return (
    <Button
      id="continue-to-login-button"
      size="3"
      variant="solid"
      onClick={() => loginWithRedirect()}
    >
      Continue
    </Button>
  );
}
