// @generated by protoc-gen-es v2.0.0 with parameter "target=js+dts,import_extension=.js"
// @generated from file dbmanagement/config_store.proto (package stately.dbmanagement, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file dbmanagement/config_store.proto.
 */
export const file_dbmanagement_config_store =
  /*@__PURE__*/
  fileDesc(
    "Ch9kYm1hbmFnZW1lbnQvY29uZmlnX3N0b3JlLnByb3RvEhRzdGF0ZWx5LmRibWFuYWdlbWVudCI1CgxCYWNrdXBDb25maWcSJQodcG9pbnRfaW5fdGltZV9iYWNrdXBzX2VuYWJsZWQYASABKAgiPAoTUmVxdWVzdFJhdGVMaW1pdGluZxISCgp3cml0ZV9yYXRlGAEgASgNEhEKCXJlYWRfcmF0ZRgCIAEoDSIpChNDdXN0b21UYWJsZUFjY2Vzc29yEhIKCnRhYmxlX25hbWUYASABKAkiDwoNRW5mb3JjZVNjaGVtYUKdAQoYY29tLnN0YXRlbHkuZGJtYW5hZ2VtZW50QhBDb25maWdTdG9yZVByb3RvUAGiAgNTRFiqAhRTdGF0ZWx5LkRibWFuYWdlbWVudMoCFFN0YXRlbHlcRGJtYW5hZ2VtZW504gIgU3RhdGVseVxEYm1hbmFnZW1lbnRcR1BCTWV0YWRhdGHqAhVTdGF0ZWx5OjpEYm1hbmFnZW1lbnRiBnByb3RvMw",
  );

/**
 * Describes the message stately.dbmanagement.BackupConfig.
 * Use `create(BackupConfigSchema)` to create a new message.
 */
export const BackupConfigSchema = /*@__PURE__*/ messageDesc(file_dbmanagement_config_store, 0);

/**
 * Describes the message stately.dbmanagement.RequestRateLimiting.
 * Use `create(RequestRateLimitingSchema)` to create a new message.
 */
export const RequestRateLimitingSchema =
  /*@__PURE__*/
  messageDesc(file_dbmanagement_config_store, 1);

/**
 * Describes the message stately.dbmanagement.CustomTableAccessor.
 * Use `create(CustomTableAccessorSchema)` to create a new message.
 */
export const CustomTableAccessorSchema =
  /*@__PURE__*/
  messageDesc(file_dbmanagement_config_store, 2);

/**
 * Describes the message stately.dbmanagement.EnforceSchema.
 * Use `create(EnforceSchemaSchema)` to create a new message.
 */
export const EnforceSchemaSchema = /*@__PURE__*/ messageDesc(file_dbmanagement_config_store, 3);
