import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Flex } from "@radix-ui/themes";
import { GetPropDefTypes, marginPropDefs } from "@radix-ui/themes/props";
import { isWindows } from "utils/browsers";
import styles from "./DialogButtons.m.css";

type MarginProps = GetPropDefTypes<typeof marginPropDefs>;

/**
 * The buttons at the bottom of a dialog. Handles the different default button order on Windows.
 */
export default function DialogButtons({
  children,
  progress,
  error,
  mt = "4",
}: {
  /**
   * Children should be the list of buttons.
   */
  children: React.ReactNode;
  /** An optional progress message - e.g. to show an in-progress operation. */
  progress?: React.ReactNode;
  /** An optional error to display for when the action failed. */
  error?: Error;
  mt?: MarginProps["mt"];
}) {
  if (isWindows() && Array.isArray(children)) {
    children = children.toReversed();
  }
  return (
    <Flex gap="3" mt={mt} justify="end">
      {Boolean(progress) && (
        <div>
          {progress} <FontAwesomeIcon icon={faRefresh} spin />
        </div>
      )}
      {error && <div className={styles.error}>{error.message}</div>}
      {children}
    </Flex>
  );
}
