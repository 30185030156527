import { Flex, Heading } from "@radix-ui/themes";
import { useParams } from "react-router";
import { useStoreInfo } from "state/user/hooks";
import AdminOnly from "ui/shared/AdminOnly";
import { DeleteStoreButton } from "ui/store/DeleteStoreButton";
import { usePageTitle } from "ui/utils/usePageTitle";
import { StoreTile } from "./StoreTile";

export default function StorePage() {
  usePageTitle("Store");

  const { storeId } = useParams<{ storeId: string }>();
  // This is the store info from Whoami - we might want to load more detail in a separate call
  const store = useStoreInfo(BigInt(storeId ?? "0"));
  if (!store) {
    return <div>Store not found</div>;
  }

  return (
    <Flex gap="4" direction="column" mt="4">
      <Heading size="7" trim="end">
        Store Details
      </Heading>

      <StoreTile store={store} />

      <AdminOnly>
        <DeleteStoreButton store={store} />
      </AdminOnly>
    </Flex>
  );
}
