import { Outlet } from "react-router";
import { useLoadUser } from "state/user/hooks";
import ErrorBoundary from "ui/errors/ErrorBoundary";
import Menu from "ui/menu/Menu";
import ScrollToTop from "ui/utils/ScrollToTop";

export default function Shell() {
  useLoadUser();

  return (
    <>
      <ScrollToTop />
      <Menu />
      <main>
        <ErrorBoundary name="Page">
          <Outlet />
        </ErrorBoundary>
      </main>
    </>
  );
}
