/**
 * Transform an async function into a version that will only execute once at a time - if there's already
 * a version going, the existing promise will be returned instead of running it again.
 */
export function dedupePromise<T extends unknown[], K>(
  func: (...args: T) => Promise<K>,
): (...args: T) => Promise<K> {
  let promiseCache: Promise<K> | null = null;
  return async (...args: T) => {
    if (promiseCache) {
      return promiseCache;
    }
    promiseCache = func(...args);
    try {
      return await promiseCache;
    } finally {
      promiseCache = null;
    }
  };
}

// setTimeout as a promise
export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Produce an error message either from an Error object, or a stringy
 * representation of a non-Error object. Meant to be used when displaying or
 * logging errors from catch blocks.
 */
export function errorMessage(e: unknown): string {
  return e instanceof Error ? e.message : JSON.stringify(e);
}

/**
 * If the parameter is not an Error, wrap a stringified version of it in an
 * Error. Meant to be used from catch blocks where the thrown type is not known.
 */
export function convertToError(e: unknown): Error {
  if (e instanceof Error) {
    return e;
  }
  return new Error(JSON.stringify(e));
}
