import { useMemo } from "react";
import { createManagementClient } from ".";
import { useStatelyClient } from "../client/useStatelyClient";

// useManagementClient returns a client which is pre-configured to talk to the stately management backend service.
//
// @example
// const client = useManagementClient()
// const stores = await client.ListStores(...)
export const useManagementClient = () => {
  const client = useStatelyClient();
  return useMemo(() => createManagementClient(client), [client]);
};
