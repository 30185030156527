import {
  faBook,
  faCircleQuestion,
  faGaugeHigh,
  faPersonDigging,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Link, Text, ThemePanel } from "@radix-ui/themes";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useIsAdmin } from "state/user/hooks";
import Logo from "ui/logo/Logo";
import ProfileAvatar from "ui/menu/ProfileAvatar";
import LogoutButton from "./LogoutButton";
import styles from "./Menu.m.css";

export default function Menu() {
  const [showThemeEditor, setShowThemeEditor] = useState(false);
  const isAdmin = useIsAdmin();
  const noAdmin = localStorage.getItem("noAdmin");
  const toggleNoAdmin = () => {
    if (noAdmin) {
      localStorage.removeItem("noAdmin");
    } else {
      localStorage.setItem("noAdmin", "true");
    }
    window.location.reload();
  };

  return (
    <nav aria-label="Primary Navigation" className={styles.header}>
      <RouterLink to="/" title="Stately Cloud Console Home" className={styles.logo}>
        <Logo height={36} alt="Stately Cloud Logo" text />
      </RouterLink>
      <ProfileAvatar />
      <ul className={styles.links}>
        <li>
          <Link asChild>
            <RouterLink to="/">
              <FontAwesomeIcon icon={faGaugeHigh} />
              Dashboard
            </RouterLink>
          </Link>
        </li>
        <li>
          <Link href="https://docs.stately.cloud/">
            <FontAwesomeIcon icon={faBook} />
            Documentation
          </Link>
        </li>
        <li>
          <Link asChild>
            <RouterLink to="/account">
              <FontAwesomeIcon icon={faUser} />
              Your Account
            </RouterLink>
          </Link>
        </li>
        <li>
          <Link asChild>
            <RouterLink to="/support">
              <FontAwesomeIcon icon={faCircleQuestion} />
              Contact Us
            </RouterLink>
          </Link>
        </li>
        {isAdmin && (
          <>
            <li>
              <Link asChild>
                <RouterLink to="/admin">
                  <FontAwesomeIcon icon={faPersonDigging} />
                  Admin
                </RouterLink>
              </Link>
            </li>
          </>
        )}
      </ul>
      <Text size="1">
        Prefer to drive from the terminal? Grab our{" "}
        <Link href={$STATELY_CLI_DOWNLOAD_LINK} className={styles.highlighted}>
          CLI utility
        </Link>
        .
      </Text>
      {$featureFlags.themeEditor && (
        <Button onClick={() => setShowThemeEditor((s) => !s)} size="1" variant="soft">
          Theme Editor
        </Button>
      )}
      {noAdmin ? (
        <Button variant="soft" onClick={toggleNoAdmin}>
          Become admin
        </Button>
      ) : (
        isAdmin && (
          <Button variant="soft" onClick={toggleNoAdmin}>
            Become non-admin
          </Button>
        )
      )}
      {$featureFlags.themeEditor && showThemeEditor && <ThemePanel />}
      <LogoutButton />
    </nav>
  );
}
