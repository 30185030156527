import { type Interceptor } from "@connectrpc/connect";

/**
 * Only used from our website, this allows us to disable admin functionality
 * even if the user would otherwise be an admin.
 */
export const noAdminMiddleware: Interceptor = (next) => (req) => {
  req.header.set("X-Stately-NoAdmin", "1");
  return next(req);
};
