import { faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dialog } from "@radix-ui/themes";
import { useCallback, useState } from "react";
import { StoreID } from "services/data/data";
import { put, SchemaAuditLogEntry } from "services/schema";
import { useSchemaClient } from "services/schema/useSchemaClient";
import DialogButtons from "ui/shared/DialogButtons";
import { useSubmitFormHandler } from "ui/utils/forms";

export function RollbackSchemaButton({
  entry,
  onSuccess,
  storeId,
}: {
  entry: SchemaAuditLogEntry;
  onSuccess: () => void;
  storeId: StoreID;
}) {
  const [showDialog, setShowDialog] = useState(false);
  const close = useCallback(() => setShowDialog(false), []);
  const client = useSchemaClient();

  const handleSubmit = useCallback(async () => {
    const modifiedAtTime = new Date(Number(entry.modifiedAtMicros / 1000n));
    await put(
      client,
      storeId,
      entry.schema!.fileDescriptor!,
      false,
      `Rollback to ${modifiedAtTime.toISOString()}`,
      true,
    );
    onSuccess();
  }, [entry.modifiedAtMicros, entry.schema, client, storeId, onSuccess]);

  return (
    <Dialog.Root open={showDialog} onOpenChange={setShowDialog}>
      <Dialog.Trigger>
        <Button size="1" mt="4" ml="4">
          <FontAwesomeIcon icon={faUndo} /> Revert
        </Button>
      </Dialog.Trigger>
      <Dialog.Content>
        <RollbackSchemaDialog onSubmit={handleSubmit} close={close} />
      </Dialog.Content>
    </Dialog.Root>
  );
}

function RollbackSchemaDialog({
  onSubmit,
  close,
}: {
  path?: string;
  placeholder?: string;
  onSubmit: () => Promise<void>;
  close: () => void;
}) {
  const [loading, handleSubmit, error] = useSubmitFormHandler(onSubmit, close);

  return (
    <>
      <Dialog.Title>Rollback Schema</Dialog.Title>
      <form onSubmit={handleSubmit}>
        <Dialog.Description size="2" color="red">
          WARNING: This can result in loss of data.
        </Dialog.Description>

        <DialogButtons progress={loading && "Rolling back..."} error={error}>
          <Dialog.Close>
            <Button variant="soft" color="gray">
              Cancel
            </Button>
          </Dialog.Close>
          <Button type="submit" variant="solid">
            Rollback
          </Button>
        </DialogButtons>
      </form>
    </>
  );
}
