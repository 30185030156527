import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Collapsible from "@radix-ui/react-collapsible";
import { Box, Button, Card, Heading, Text } from "@radix-ui/themes";
import { useReducer } from "react";
import { useParams } from "react-router";
import { SchemaAuditLogEntry } from "services/schema";
import { RollbackSchemaButton } from "./RollbackSchemaButton";
import SchemaContent from "./SchemaContent";

/**
 * A single entry in the schema audit log.
 */
export default function AuditLogEntry({
  entry,
  showRollbackButton = false,
  onRollbackSuccess,
}: {
  entry: SchemaAuditLogEntry;
  showRollbackButton: boolean;
  onRollbackSuccess: () => void;
}) {
  const { storeId } = useParams<{ storeId: string }>();
  const storeID = BigInt(storeId ?? "0");

  const [open, toggleOpen] = useReducer((open) => !open, false);
  const modifiedAtTime = new Date(Number(entry.modifiedAtMicros / 1000n));

  return (
    <Card>
      <Heading size="4">
        Modified by {entry.modifiedBy} at{" "}
        <time dateTime={modifiedAtTime.toISOString()}>{modifiedAtTime.toLocaleString()}</time>
      </Heading>
      <Text>{entry.changeDescription || "No change description."}</Text>
      {entry.schema?.fileDescriptor && (
        <Box>
          <Button size="1" mt="4" onClick={toggleOpen}>
            {open ? (
              <>
                <FontAwesomeIcon icon={faCaretUp} /> Hide Schema
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faCaretDown} /> Show Schema
              </>
            )}
          </Button>
          {showRollbackButton && (
            <RollbackSchemaButton entry={entry} storeId={storeID} onSuccess={onRollbackSuccess} />
          )}
          <Collapsible.Root open={open}>
            <Collapsible.Content>
              <SchemaContent schema={entry.schema} />
            </Collapsible.Content>
          </Collapsible.Root>
        </Box>
      )}
    </Card>
  );
}
