// @generated by protoc-gen-es v2.0.0 with parameter "target=js+dts,import_extension=.js"
// @generated from file schemaservice/list_audit_log.proto (package stately.schemaservice, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_schemaservice_schema } from "./schema_pb.js";

/**
 * Describes the file schemaservice/list_audit_log.proto.
 */
export const file_schemaservice_list_audit_log =
  /*@__PURE__*/
  fileDesc(
    "CiJzY2hlbWFzZXJ2aWNlL2xpc3RfYXVkaXRfbG9nLnByb3RvEhVzdGF0ZWx5LnNjaGVtYXNlcnZpY2UiNgoTTGlzdEF1ZGl0TG9nUmVxdWVzdBIQCghzdG9yZV9pZBgCIAEoBBINCgVsaW1pdBgDIAEoDSJTChRMaXN0QXVkaXRMb2dSZXNwb25zZRI7CgdlbnRyaWVzGAEgAygLMiouc3RhdGVseS5zY2hlbWFzZXJ2aWNlLlNjaGVtYUF1ZGl0TG9nRW50cnkilgEKE1NjaGVtYUF1ZGl0TG9nRW50cnkSMgoGc2NoZW1hGAEgASgLMiIuc3RhdGVseS5zY2hlbWFzZXJ2aWNlLlNjaGVtYU1vZGVsEhoKEm1vZGlmaWVkX2F0X21pY3JvcxgCIAEoBBITCgttb2RpZmllZF9ieRgDIAEoCRIaChJjaGFuZ2VfZGVzY3JpcHRpb24YBCABKAlCowEKGWNvbS5zdGF0ZWx5LnNjaGVtYXNlcnZpY2VCEUxpc3RBdWRpdExvZ1Byb3RvUAGiAgNTU1iqAhVTdGF0ZWx5LlNjaGVtYXNlcnZpY2XKAhVTdGF0ZWx5XFNjaGVtYXNlcnZpY2XiAiFTdGF0ZWx5XFNjaGVtYXNlcnZpY2VcR1BCTWV0YWRhdGHqAhZTdGF0ZWx5OjpTY2hlbWFzZXJ2aWNlYgZwcm90bzM",
    [file_schemaservice_schema],
  );

/**
 * Describes the message stately.schemaservice.ListAuditLogRequest.
 * Use `create(ListAuditLogRequestSchema)` to create a new message.
 */
export const ListAuditLogRequestSchema =
  /*@__PURE__*/
  messageDesc(file_schemaservice_list_audit_log, 0);

/**
 * Describes the message stately.schemaservice.ListAuditLogResponse.
 * Use `create(ListAuditLogResponseSchema)` to create a new message.
 */
export const ListAuditLogResponseSchema =
  /*@__PURE__*/
  messageDesc(file_schemaservice_list_audit_log, 1);

/**
 * Describes the message stately.schemaservice.SchemaAuditLogEntry.
 * Use `create(SchemaAuditLogEntrySchema)` to create a new message.
 */
export const SchemaAuditLogEntrySchema =
  /*@__PURE__*/
  messageDesc(file_schemaservice_list_audit_log, 2);
