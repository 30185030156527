import { useAuth0 } from "@auth0/auth0-react";
import { Flex, Text } from "@radix-ui/themes";

export default function ProfileAvatar() {
  const { user } = useAuth0();

  return (
    user && (
      <Flex direction="column">
        <Text weight="bold">{user.name}</Text>
        <Text weight="light" size="2">
          {user.email}
        </Text>
      </Flex>
    )
  );
}
