import { Button, Card, Flex, Heading, Text } from "@radix-ui/themes";
import { useSearchParams } from "react-router-dom";
import Logo from "ui/logo/Logo";
import LoginButton from "ui/menu/LoginButton";
import styles from "./UnauthenticatedHome.m.css";

export default function UnauthenticatedHome() {
  const [params] = useSearchParams();

  let contents = (
    <>
      <Text as="p" size="3">
        You are not authenticated. Click the Continue button below to select your identity provider.
        If you don't have an account yet, we'll create one for you automatically.
      </Text>
      <LoginButton />
    </>
  );

  // This means they don't have the "preview" role
  if (params.get("error") && params.get("error_description")?.includes("Please contact")) {
    contents = (
      <>
        <Heading>Members Only!</Heading>
        <Text as="p" size="3">
          The Stately Console is currently only open to invited users. If you've already been in
          contact with us, we'll add you to the access list and you can log in again. Otherwise,
          please contact <a href="mailto:support@stately.cloud">support@stately.cloud</a> for
          access.
        </Text>
        <Button
          id="continue-to-login-button"
          size="3"
          variant="solid"
          onClick={() => (window.location.href = "/")}
        >
          Reload
        </Button>
      </>
    );
  }

  return (
    <div className={styles.container}>
      <Card size="3" style={{ maxWidth: 480 }}>
        <Flex gap="4" align="center" direction="column">
          <Logo className={styles.logo} text alt="Stately Cloud Logo" />
          {contents}
        </Flex>
      </Card>
    </div>
  );
}
