// @generated by protoc-gen-es v2.0.0 with parameter "target=js+dts,import_extension=.js"
// @generated from file dbmanagement/describe_store.proto (package stately.dbmanagement, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_dbmanagement_store_info } from "./store_info_pb.js";

/**
 * Describes the file dbmanagement/describe_store.proto.
 */
export const file_dbmanagement_describe_store =
  /*@__PURE__*/
  fileDesc(
    "CiFkYm1hbmFnZW1lbnQvZGVzY3JpYmVfc3RvcmUucHJvdG8SFHN0YXRlbHkuZGJtYW5hZ2VtZW50IigKFERlc2NyaWJlU3RvcmVSZXF1ZXN0EhAKCHN0b3JlX2lkGAEgASgEIkkKFURlc2NyaWJlU3RvcmVSZXNwb25zZRIwCgVzdG9yZRgBIAEoCzIhLnN0YXRlbHkuZGJtYW5hZ2VtZW50LlN0b3JlQ29uZmlnQp8BChhjb20uc3RhdGVseS5kYm1hbmFnZW1lbnRCEkRlc2NyaWJlU3RvcmVQcm90b1ABogIDU0RYqgIUU3RhdGVseS5EYm1hbmFnZW1lbnTKAhRTdGF0ZWx5XERibWFuYWdlbWVudOICIFN0YXRlbHlcRGJtYW5hZ2VtZW50XEdQQk1ldGFkYXRh6gIVU3RhdGVseTo6RGJtYW5hZ2VtZW50YgZwcm90bzM",
    [file_dbmanagement_store_info],
  );

/**
 * Describes the message stately.dbmanagement.DescribeStoreRequest.
 * Use `create(DescribeStoreRequestSchema)` to create a new message.
 */
export const DescribeStoreRequestSchema =
  /*@__PURE__*/
  messageDesc(file_dbmanagement_describe_store, 0);

/**
 * Describes the message stately.dbmanagement.DescribeStoreResponse.
 * Use `create(DescribeStoreResponseSchema)` to create a new message.
 */
export const DescribeStoreResponseSchema =
  /*@__PURE__*/
  messageDesc(file_dbmanagement_describe_store, 1);
