// @generated by protoc-gen-es v2.0.0 with parameter "target=js+dts,import_extension=.js"
// @generated from file schemaservice/get.proto (package stately.schemaservice, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_schemaservice_schema } from "./schema_pb.js";

/**
 * Describes the file schemaservice/get.proto.
 */
export const file_schemaservice_get =
  /*@__PURE__*/
  fileDesc(
    "ChdzY2hlbWFzZXJ2aWNlL2dldC5wcm90bxIVc3RhdGVseS5zY2hlbWFzZXJ2aWNlIh4KCkdldFJlcXVlc3QSEAoIc3RvcmVfaWQYASABKAQiQQoLR2V0UmVzcG9uc2USMgoGc2NoZW1hGAEgASgLMiIuc3RhdGVseS5zY2hlbWFzZXJ2aWNlLlNjaGVtYU1vZGVsQpoBChljb20uc3RhdGVseS5zY2hlbWFzZXJ2aWNlQghHZXRQcm90b1ABogIDU1NYqgIVU3RhdGVseS5TY2hlbWFzZXJ2aWNlygIVU3RhdGVseVxTY2hlbWFzZXJ2aWNl4gIhU3RhdGVseVxTY2hlbWFzZXJ2aWNlXEdQQk1ldGFkYXRh6gIWU3RhdGVseTo6U2NoZW1hc2VydmljZWIGcHJvdG8z",
    [file_schemaservice_schema],
  );

/**
 * Describes the message stately.schemaservice.GetRequest.
 * Use `create(GetRequestSchema)` to create a new message.
 */
export const GetRequestSchema = /*@__PURE__*/ messageDesc(file_schemaservice_get, 0);

/**
 * Describes the message stately.schemaservice.GetResponse.
 * Use `create(GetResponseSchema)` to create a new message.
 */
export const GetResponseSchema = /*@__PURE__*/ messageDesc(file_schemaservice_get, 1);
