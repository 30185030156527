import { useMemo } from "react";
import { Region } from "services/api/dbmanagement/region_pb.js";
import { createSchemaClient } from ".";
import { useStatelyClient } from "../client/useStatelyClient";

// useSchemaClient returns a client which can be used for schema API operations.
//
// @example
// const client = useSchemaClient()
// const schema = await get(client, 42n)
export const useSchemaClient = () => {
  const client = useStatelyClient()(Region.AWS_US_WEST_2);
  return useMemo(() => createSchemaClient(client), [client]);
};
