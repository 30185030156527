// @generated by protoc-gen-es v2.0.0 with parameter "target=js+dts,import_extension=.js"
// @generated from file schemaservice/delete.proto (package stately.schemaservice, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file schemaservice/delete.proto.
 */
export const file_schemaservice_delete =
  /*@__PURE__*/
  fileDesc(
    "ChpzY2hlbWFzZXJ2aWNlL2RlbGV0ZS5wcm90bxIVc3RhdGVseS5zY2hlbWFzZXJ2aWNlIiEKDURlbGV0ZVJlcXVlc3QSEAoIc3RvcmVfaWQYASABKAQiEAoORGVsZXRlUmVzcG9uc2VCnQEKGWNvbS5zdGF0ZWx5LnNjaGVtYXNlcnZpY2VCC0RlbGV0ZVByb3RvUAGiAgNTU1iqAhVTdGF0ZWx5LlNjaGVtYXNlcnZpY2XKAhVTdGF0ZWx5XFNjaGVtYXNlcnZpY2XiAiFTdGF0ZWx5XFNjaGVtYXNlcnZpY2VcR1BCTWV0YWRhdGHqAhZTdGF0ZWx5OjpTY2hlbWFzZXJ2aWNlYgZwcm90bzM",
  );

/**
 * Describes the message stately.schemaservice.DeleteRequest.
 * Use `create(DeleteRequestSchema)` to create a new message.
 */
export const DeleteRequestSchema = /*@__PURE__*/ messageDesc(file_schemaservice_delete, 0);

/**
 * Describes the message stately.schemaservice.DeleteResponse.
 * Use `create(DeleteResponseSchema)` to create a new message.
 */
export const DeleteResponseSchema = /*@__PURE__*/ messageDesc(file_schemaservice_delete, 1);
