// @generated by protoc-gen-es v2.0.0 with parameter "target=js+dts,import_extension=.js"
// @generated from file schemaservice/schema.proto (package stately.schemaservice, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_google_protobuf_descriptor } from "@bufbuild/protobuf/wkt";

/**
 * Describes the file schemaservice/schema.proto.
 */
export const file_schemaservice_schema =
  /*@__PURE__*/
  fileDesc(
    "ChpzY2hlbWFzZXJ2aWNlL3NjaGVtYS5wcm90bxIVc3RhdGVseS5zY2hlbWFzZXJ2aWNlIqIBCgtTY2hlbWFNb2RlbBI9Cg9maWxlX2Rlc2NyaXB0b3IYASABKAsyJC5nb29nbGUucHJvdG9idWYuRmlsZURlc2NyaXB0b3JQcm90bxIfChdsYXN0X21vZGlmaWVkX2F0X21pY3JvcxgCIAEoBBIZChFjcmVhdGVkX2F0X21pY3JvcxgDIAEoBBIYChBmb3JtYXR0ZWRfc2NoZW1hGAQgASgJQp0BChljb20uc3RhdGVseS5zY2hlbWFzZXJ2aWNlQgtTY2hlbWFQcm90b1ABogIDU1NYqgIVU3RhdGVseS5TY2hlbWFzZXJ2aWNlygIVU3RhdGVseVxTY2hlbWFzZXJ2aWNl4gIhU3RhdGVseVxTY2hlbWFzZXJ2aWNlXEdQQk1ldGFkYXRh6gIWU3RhdGVseTo6U2NoZW1hc2VydmljZWIGcHJvdG8z",
    [file_google_protobuf_descriptor],
  );

/**
 * Describes the message stately.schemaservice.SchemaModel.
 * Use `create(SchemaModelSchema)` to create a new message.
 */
export const SchemaModelSchema = /*@__PURE__*/ messageDesc(file_schemaservice_schema, 0);
