import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Callout, Flex, Heading } from "@radix-ui/themes";
import { useOrganizations } from "state/user/hooks";
import { OrganizationSection } from "ui/organization/OrganizationSection";

export default function AuthenticatedHome() {
  const organizations = useOrganizations();

  // TODO: style as a menu of selection options with org headers.

  return (
    <Flex gap="4" direction="column" mt="4">
      <Heading size="7" trim="end">
        Your Stately Resources
      </Heading>

      {organizations.map((org) => (
        <OrganizationSection org={org} key={org.organizationId.toString()} />
      ))}

      {organizations.length < 2 && (
        <Callout.Root size="1" variant="outline">
          <Callout.Icon>
            <FontAwesomeIcon icon={faCircleInfo} />
          </Callout.Icon>
          <Callout.Text>
            One organization and project were automatically created for you when you logged in, but
            you can contact <a href="mailto:support@stately.cloud">support@stately.cloud</a> to have
            more created, or to add more users to an organization.
          </Callout.Text>
        </Callout.Root>
      )}
    </Flex>
  );
}
