import { faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Flex, Heading } from "@radix-ui/themes";
import { useProjectsForOrg } from "state/user/hooks";
import { Organization } from "state/user/types";
import { ProjectSection } from "ui/project/ProjectSection";

export function OrganizationSection({ org }: { org: Organization }) {
  const projects = useProjectsForOrg(org.organizationId);

  return (
    <Flex direction="column" gap="4">
      <Heading weight="light" size="5" trim="end">
        <FontAwesomeIcon icon={faUserGroup} /> Organization "{org.name}"
      </Heading>
      {projects.map((project) => (
        <ProjectSection key={project.projectId.toString()} project={project} />
      ))}
    </Flex>
  );
}
