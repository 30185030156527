/** Use this to wrap components that should only be shown to admins. */
// TODO: This should be based off something in the token or whoami response.

import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Collapsible from "@radix-ui/react-collapsible";
import { Flex, Heading, IconButton, Separator } from "@radix-ui/themes";
import React from "react";
import { useIsAdmin } from "state/user/hooks";
import styles from "./AdminOnly.m.css";

// TODO: Nothing should rely on this - these permissions *must* be validated on the backend.
export default function AdminOnly({ children }: { children: React.ReactNode }) {
  const isAdmin = useIsAdmin();
  const [isCollapsibleOpen, setIsCollapsibleOpen] = React.useState(false);
  if (isAdmin) {
    return (
      <Collapsible.Collapsible open={isCollapsibleOpen} onOpenChange={setIsCollapsibleOpen}>
        <div className={styles.adminPanel}>
          <Flex direction="row" gap="4" align="center">
            <Collapsible.Trigger asChild>
              <IconButton variant="outline" color="red">
                {isCollapsibleOpen ? (
                  <FontAwesomeIcon icon={faCaretDown} />
                ) : (
                  <FontAwesomeIcon icon={faCaretRight} />
                )}
              </IconButton>
            </Collapsible.Trigger>
            <Heading size="4">Admin Only</Heading>
          </Flex>
          <Collapsible.Content>
            <Separator size="4" my="2" />
            <div>{children}</div>
          </Collapsible.Content>
        </div>
      </Collapsible.Collapsible>
    );
  }

  return null;
}
