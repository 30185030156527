import { Text } from "@radix-ui/themes";
import styles from "./TerminalSnippet.m.css";

// TODO: use shiki or something for highlighting

export default function TerminalSnippet({
  children,
  language,
}: {
  children: React.ReactNode;
  language: string;
}) {
  return (
    <div className={styles.terminal}>
      {children}
      <Text size="1" className={styles.language}>
        {language}
      </Text>
    </div>
  );
}
