import { faProjectDiagram } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Flex, Heading } from "@radix-ui/themes";
import { useStoresForProject } from "state/user/hooks";
import { Project } from "state/user/types";
import { StoreTile } from "ui/store/StoreTile";

export function ProjectSection({ project }: { project: Project }) {
  const stores = useStoresForProject(project.projectId);
  return (
    <>
      <Heading weight="light" size="4" trim="end" ml="3">
        <FontAwesomeIcon icon={faProjectDiagram} /> Project "{project.name}"
      </Heading>
      <Flex gap="4" direction="column" ml="3">
        {stores.map((store) => (
          <StoreTile key={store.storeId.toString()} store={store} />
        ))}
      </Flex>
    </>
  );
}
