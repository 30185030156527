// @generated by protoc-gen-es v2.0.0 with parameter "target=js+dts,import_extension=.js"
// @generated from file user/create_organization.proto (package stately.user, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file user/create_organization.proto.
 */
export const file_user_create_organization =
  /*@__PURE__*/
  fileDesc(
    "Ch51c2VyL2NyZWF0ZV9vcmdhbml6YXRpb24ucHJvdG8SDHN0YXRlbHkudXNlciJKChlDcmVhdGVPcmdhbml6YXRpb25SZXF1ZXN0EgwKBG5hbWUYASABKAkSHwoXZG9fbm90X2FkZF9jdXJyZW50X3VzZXIYAiABKAgiNQoaQ3JlYXRlT3JnYW5pemF0aW9uUmVzcG9uc2USFwoPb3JnYW5pemF0aW9uX2lkGAEgASgEQnwKEGNvbS5zdGF0ZWx5LnVzZXJCF0NyZWF0ZU9yZ2FuaXphdGlvblByb3RvUAGiAgNTVViqAgxTdGF0ZWx5LlVzZXLKAgxTdGF0ZWx5XFVzZXLiAhhTdGF0ZWx5XFVzZXJcR1BCTWV0YWRhdGHqAg1TdGF0ZWx5OjpVc2VyYgZwcm90bzM",
  );

/**
 * Describes the message stately.user.CreateOrganizationRequest.
 * Use `create(CreateOrganizationRequestSchema)` to create a new message.
 */
export const CreateOrganizationRequestSchema =
  /*@__PURE__*/
  messageDesc(file_user_create_organization, 0);

/**
 * Describes the message stately.user.CreateOrganizationResponse.
 * Use `create(CreateOrganizationResponseSchema)` to create a new message.
 */
export const CreateOrganizationResponseSchema =
  /*@__PURE__*/
  messageDesc(file_user_create_organization, 1);
