import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AlertDialog, Button, Text } from "@radix-ui/themes";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import { useManagementClient } from "services/management/useManagementClient";
import { useDeleteStore } from "state/user/hooks";
import { Store } from "state/user/types";
import DialogButtons from "ui/shared/DialogButtons";
import { useSubmitFormHandler } from "ui/utils/forms";

export function DeleteStoreButton({ store }: { store: Store }) {
  const navigate = useNavigate();
  const client = useManagementClient();
  const deleteStore = useDeleteStore();
  const [showDialog, setShowDialog] = useState(false);
  const close = useCallback(() => setShowDialog(false), []);

  const handleDelete = useCallback(async () => {
    await deleteStore(client, store.defaultRegion, store.storeId);
    navigate(`/project/${store.projectId}`);
  }, [deleteStore, client, store.storeId, store.defaultRegion, store.projectId, navigate]);

  return (
    <>
      <AlertDialog.Root open={showDialog} onOpenChange={setShowDialog}>
        <AlertDialog.Trigger>
          <Button>
            <FontAwesomeIcon icon={faMinusCircle} />
            <Text>Delete Store</Text>
          </Button>
        </AlertDialog.Trigger>
        <AlertDialog.Content>
          <DeleteStoreDialog store={store} onSubmit={handleDelete} close={close} />
        </AlertDialog.Content>
      </AlertDialog.Root>
    </>
  );
}

// This gets conditionally rendered by Dialog.Content so its state resets once closed.
function DeleteStoreDialog({
  store,
  onSubmit,
  close,
}: {
  store: Store;
  onSubmit: () => Promise<void>;
  close: () => void;
}) {
  const [loading, handleSubmit, error] = useSubmitFormHandler(onSubmit, close);

  return (
    <form onSubmit={handleSubmit}>
      <AlertDialog.Title>Delete Store</AlertDialog.Title>
      <AlertDialog.Description size="2" color="red">
        Are you sure you want to delete the store "{store.name}" and all data in it? This operation
        is irreversible.
      </AlertDialog.Description>

      <DialogButtons progress={loading && "Deleting store..."} error={error}>
        <AlertDialog.Cancel>
          <Button variant="soft" color="gray">
            Cancel
          </Button>
        </AlertDialog.Cancel>
        <AlertDialog.Action>
          <Button type="submit" variant="solid" color="red">
            Delete Store
          </Button>
        </AlertDialog.Action>
      </DialogButtons>
    </form>
  );
}
