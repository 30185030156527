import { useAuth0 } from "@auth0/auth0-react";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@radix-ui/themes";

export default function LogoutButton() {
  const { logout } = useAuth0();
  const handleClick = () => logout({ logoutParams: { returnTo: window.location.origin } });
  return (
    <Button id="logout-button" onClick={handleClick} size="1" variant="soft">
      <FontAwesomeIcon icon={faRightFromBracket} /> Log Out
    </Button>
  );
}
