import { type Interceptor } from "@connectrpc/connect";

/**
 * Only used from our website, this requests JSON versions of Items from the
 * server.
 */
export const jsonResponseMiddleware: Interceptor = (next) => (req) => {
  req.header.set("sc-rf", "application/json");
  return next(req);
};
