import { type Interceptor } from "@connectrpc/connect";

export type AuthTokenProvider = () => Promise<string>;

/**
 * Create a middleware that fetches the auth token and adds it to the request as
 * a header.
 */
export function createAuthMiddleware(getToken: AuthTokenProvider): Interceptor {
  return (next) => async (req) => {
    req.header.set("Authorization", `Bearer ${await getToken()}`);
    return next(req);
  };
}
