import { useEffect } from "react";

export function usePageTitle(title: string, active?: boolean) {
  useEffect(() => {
    if (active !== false) {
      const titleElem = document.getElementsByTagName("title")[0];
      titleElem.textContent = `Stately Cloud Console - ${title}`;
      return () => {
        titleElem.textContent = `Stately Cloud Console`;
      };
    }
  }, [active, title]);
}
