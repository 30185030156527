// @generated by protoc-gen-es v2.0.0 with parameter "target=js+dts,import_extension=.js"
// @generated from file user/organization.proto (package stately.user, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file user/organization.proto.
 */
export const file_user_organization =
  /*@__PURE__*/
  fileDesc(
    "Chd1c2VyL29yZ2FuaXphdGlvbi5wcm90bxIMc3RhdGVseS51c2VyIjUKDE9yZ2FuaXphdGlvbhIXCg9vcmdhbml6YXRpb25faWQYASABKAQSDAoEbmFtZRgCIAEoCUJ2ChBjb20uc3RhdGVseS51c2VyQhFPcmdhbml6YXRpb25Qcm90b1ABogIDU1VYqgIMU3RhdGVseS5Vc2VyygIMU3RhdGVseVxVc2Vy4gIYU3RhdGVseVxVc2VyXEdQQk1ldGFkYXRh6gINU3RhdGVseTo6VXNlcmIGcHJvdG8z",
  );

/**
 * Describes the message stately.user.Organization.
 * Use `create(OrganizationSchema)` to create a new message.
 */
export const OrganizationSchema = /*@__PURE__*/ messageDesc(file_user_organization, 0);
