// @generated by protoc-gen-es v2.0.0 with parameter "target=js+dts,import_extension=.js"
// @generated from file user/user.proto (package stately.user, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file user/user.proto.
 */
export const file_user_user =
  /*@__PURE__*/
  fileDesc(
    "Cg91c2VyL3VzZXIucHJvdG8SDHN0YXRlbHkudXNlciJeCg9NaW5pbWFsVXNlckluZm8SDwoHdXNlcl9pZBgBIAEoBBIUCgxkaXNwbGF5X25hbWUYAiABKAkSDQoFZW1haWwYAyABKAkSFQoNb2F1dGhfc3ViamVjdBgEIAEoCUJuChBjb20uc3RhdGVseS51c2VyQglVc2VyUHJvdG9QAaICA1NVWKoCDFN0YXRlbHkuVXNlcsoCDFN0YXRlbHlcVXNlcuICGFN0YXRlbHlcVXNlclxHUEJNZXRhZGF0YeoCDVN0YXRlbHk6OlVzZXJiBnByb3RvMw",
  );

/**
 * Describes the message stately.user.MinimalUserInfo.
 * Use `create(MinimalUserInfoSchema)` to create a new message.
 */
export const MinimalUserInfoSchema = /*@__PURE__*/ messageDesc(file_user_user, 0);
