// @generated by protoc-gen-es v2.0.0 with parameter "target=js+dts,import_extension=.js"
// @generated from file dbmanagement/config_item.proto (package stately.dbmanagement, syntax proto3)
/* eslint-disable */

import { enumDesc, fileDesc, messageDesc, tsEnum } from "@bufbuild/protobuf/codegenv1";
import { file_dbmanagement_common } from "./common_pb.js";

/**
 * Describes the file dbmanagement/config_item.proto.
 */
export const file_dbmanagement_config_item =
  /*@__PURE__*/
  fileDesc(
    "Ch5kYm1hbmFnZW1lbnQvY29uZmlnX2l0ZW0ucHJvdG8SFHN0YXRlbHkuZGJtYW5hZ2VtZW50IswBCg5JdGVtVHlwZUNvbmZpZxIRCglpdGVtX3R5cGUYASABKAkSNAoLa2V5X2NvbmZpZ3MYAiADKAsyHy5zdGF0ZWx5LmRibWFuYWdlbWVudC5LZXlDb25maWcSMwoKdHRsX2NvbmZpZxgDIAEoCzIfLnN0YXRlbHkuZGJtYW5hZ2VtZW50LlRUTENvbmZpZxI8CgdpbmRleGVzGAQgAygLMisuc3RhdGVseS5kYm1hbmFnZW1lbnQuR3JvdXBMb2NhbEluZGV4Q29uZmlnIsQBCglUVExDb25maWcSLwoGc291cmNlGAEgASgOMh8uc3RhdGVseS5kYm1hbmFnZW1lbnQuVHRsU291cmNlEi0KBHBhdGgYAiABKAsyHy5zdGF0ZWx5LmRibWFuYWdlbWVudC5GaWVsZFBhdGgSGAoQZHVyYXRpb25fc2Vjb25kcxgDIAEoBBI9CgxpbnRlcnByZXRfYXMYBCABKA4yJy5zdGF0ZWx5LmRibWFuYWdlbWVudC5OdW1iZXJJbnRlcnByZXRBcyJKCglLZXlDb25maWcSPQoIa2V5X3BhdGgYASADKAsyKy5zdGF0ZWx5LmRibWFuYWdlbWVudC5OYW1lZEZpZWxkUGF0aFNlZ21lbnQibQoVR3JvdXBMb2NhbEluZGV4Q29uZmlnEhkKEWdyb3VwX2xvY2FsX2luZGV4GAEgASgNEjkKBHBhdGgYAiADKAsyKy5zdGF0ZWx5LmRibWFuYWdlbWVudC5OYW1lZEZpZWxkUGF0aFNlZ21lbnQqfgoJVHRsU291cmNlEhQKEFRUTF9TT1VSQ0VfVU5TRVQQABIbChdUVExfU09VUkNFX0ZST01fQ1JFQVRFRBABEiEKHVRUTF9TT1VSQ0VfRlJPTV9MQVNUX01PRElGSUVEEAISGwoXVFRMX1NPVVJDRV9BVF9USU1FU1RBTVAQAyqXAgoRTnVtYmVySW50ZXJwcmV0QXMSHgoaTlVNQkVSX0lOVEVSUFJFVF9BU19OVU1CRVIQABIpCiVOVU1CRVJfSU5URVJQUkVUX0FTX1RJTUVTVEFNUF9TRUNPTkRTEAESLgoqTlVNQkVSX0lOVEVSUFJFVF9BU19USU1FU1RBTVBfTUlMTElTRUNPTkRTEAISLgoqTlVNQkVSX0lOVEVSUFJFVF9BU19USU1FU1RBTVBfTUlDUk9TRUNPTkRTEAMSKAokTlVNQkVSX0lOVEVSUFJFVF9BU19EVVJBVElPTl9TRUNPTkRTEAQSLQopTlVNQkVSX0lOVEVSUFJFVF9BU19EVVJBVElPTl9NSUxMSVNFQ09ORFMQBUKcAQoYY29tLnN0YXRlbHkuZGJtYW5hZ2VtZW50Qg9Db25maWdJdGVtUHJvdG9QAaICA1NEWKoCFFN0YXRlbHkuRGJtYW5hZ2VtZW50ygIUU3RhdGVseVxEYm1hbmFnZW1lbnTiAiBTdGF0ZWx5XERibWFuYWdlbWVudFxHUEJNZXRhZGF0YeoCFVN0YXRlbHk6OkRibWFuYWdlbWVudGIGcHJvdG8z",
    [file_dbmanagement_common],
  );

/**
 * Describes the message stately.dbmanagement.ItemTypeConfig.
 * Use `create(ItemTypeConfigSchema)` to create a new message.
 */
export const ItemTypeConfigSchema = /*@__PURE__*/ messageDesc(file_dbmanagement_config_item, 0);

/**
 * Describes the message stately.dbmanagement.TTLConfig.
 * Use `create(TTLConfigSchema)` to create a new message.
 */
export const TTLConfigSchema = /*@__PURE__*/ messageDesc(file_dbmanagement_config_item, 1);

/**
 * Describes the message stately.dbmanagement.KeyConfig.
 * Use `create(KeyConfigSchema)` to create a new message.
 */
export const KeyConfigSchema = /*@__PURE__*/ messageDesc(file_dbmanagement_config_item, 2);

/**
 * Describes the message stately.dbmanagement.GroupLocalIndexConfig.
 * Use `create(GroupLocalIndexConfigSchema)` to create a new message.
 */
export const GroupLocalIndexConfigSchema =
  /*@__PURE__*/
  messageDesc(file_dbmanagement_config_item, 3);

/**
 * Describes the enum stately.dbmanagement.TtlSource.
 */
export const TtlSourceSchema = /*@__PURE__*/ enumDesc(file_dbmanagement_config_item, 0);

/**
 * TtlSource is an enum that specifies how the TTL of an item should be
 * calculated based on the value of a field.
 * NOTE: this has been copied from schemamodel to avoid exposing that package.
 *
 * @generated from enum stately.dbmanagement.TtlSource
 */
export const TtlSource = /*@__PURE__*/ tsEnum(TtlSourceSchema);

/**
 * Describes the enum stately.dbmanagement.NumberInterpretAs.
 */
export const NumberInterpretAsSchema = /*@__PURE__*/ enumDesc(file_dbmanagement_config_item, 1);

/**
 * NOTE: This has been copied over from schemamodel to avoid exposing that package.
 *
 * @generated from enum stately.dbmanagement.NumberInterpretAs
 */
export const NumberInterpretAs = /*@__PURE__*/ tsEnum(NumberInterpretAsSchema);
