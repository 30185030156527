export default function Logo({
  alt = "Stately Cloud Logo",
  text,
  ...svgProps
}: {
  alt?: string;
  text?: boolean;
} & React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      clipRule="evenodd"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      viewBox={`0 0 ${text ? 232 : 50} 56`}
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <title>{alt}</title>
      <g fillRule="nonzero" fill="currentColor">
        <path d="m50.136 30.756c0 3.285-.647 6.537-1.904 9.571-1.257 3.035-3.099 5.792-5.421 8.114s-5.08 4.165-8.114 5.421c-3.034 1.257-6.286 1.904-9.571 1.904-3.284 0-6.536-.647-9.57-1.904-3.035-1.256-5.792-3.099-8.114-5.421-2.323-2.322-4.165-5.079-5.422-8.114-1.256-3.034-1.903-6.286-1.903-9.571h10.886c0 1.855.366 3.692 1.075 5.405.71 1.714 1.75 3.27 3.062 4.582 1.311 1.311 2.868 2.352 4.582 3.061 1.713.71 3.55 1.075 5.404 1.075 1.855 0 3.691-.365 5.405-1.075 1.713-.709 3.27-1.75 4.582-3.061 1.311-1.312 2.352-2.868 3.061-4.582.71-1.713 1.075-3.55 1.075-5.405z" />
        <path d="m30.563 5.671c0 3.003-2.434 5.437-5.437 5.437-3.002 0-5.437-2.434-5.437-5.437s2.435-5.437 5.437-5.437c3.003 0 5.437 2.434 5.437 5.437z" />
        <path d="m19.689 5.747h10.874v22.291h-10.874z" />
        <path d="m47.029 20.116c0 3.002-2.434 5.437-5.437 5.437-3.002 0-5.437-2.435-5.437-5.437 0-3.003 2.435-5.437 5.437-5.437 3.003 0 5.437 2.434 5.437 5.437z" />
        <path d="m14.097 20.116c0 3.002-2.434 5.437-5.437 5.437-3.002 0-5.437-2.435-5.437-5.437 0-3.003 2.435-5.437 5.437-5.437 3.003 0 5.437 2.434 5.437 5.437z" />
        {text && (
          <>
            <path d="m223.425 17.839h8.458l-9.039 25.639c-1.48 4.158-3.436 7.136-5.868 8.933-2.396 1.798-5.463 2.608-9.198 2.432v-7.401c1.832 0 3.242-.334 4.229-1.004.987-.634 1.78-1.762 2.379-3.383l-10.52-25.216h8.722l5.868 16.176z" />
            <path d="m194.099 6.446h7.929v38.59h-7.929z" />
            <path d="m171.575 34.227c.916 2.467 3.031 3.7 6.344 3.7 2.149 0 3.841-.669 5.075-2.009l6.343 3.648c-2.608 3.63-6.449 5.445-11.524 5.445-4.44 0-8-1.322-10.678-3.965-2.643-2.643-3.965-5.973-3.965-9.991 0-3.982 1.304-7.295 3.912-9.938 2.643-2.679 6.026-4.018 10.149-4.018 3.842 0 7.031 1.339 9.569 4.018 2.572 2.643 3.859 5.956 3.859 9.938 0 1.128-.106 2.185-.318 3.172zm-.158-5.921h11.365c-.775-2.784-2.643-4.176-5.603-4.176-3.066 0-4.987 1.392-5.762 4.176z" />
            <path d="m161.575 25.452h-5.444v9.913c0 1.057.422 1.726 1.268 2.008s2.238.37 4.176.265v7.189c-5.039.529-8.528.053-10.466-1.427-1.939-1.516-2.908-4.194-2.908-8.035v-9.913h-4.229v-7.613h4.229v-5.022l7.93-2.378v7.4h5.444z" />
            <path d="m133.397 17.839h7.93l-.001 26.432h-7.929v-2.485c-1.868 2.15-4.493 3.225-7.876 3.225-3.489 0-6.467-1.339-8.934-4.018-2.467-2.713-3.701-6.026-3.701-9.938s1.234-7.207 3.701-9.885c2.467-2.714 5.445-4.071 8.934-4.071 3.383 0 6.008 1.075 7.876 3.225zm-10.837 17.921c1.163 1.163 2.679 1.744 4.546 1.744 1.868 0 3.384-.581 4.547-1.744s1.744-2.732 1.744-4.705c0-1.974-.581-3.542-1.744-4.705s-2.679-1.744-4.547-1.744c-1.867 0-3.383.581-4.546 1.744s-1.744 2.731-1.744 4.705c0 1.973.581 3.542 1.744 4.705z" />
            <path d="m111.292 25.452h-5.445v9.913c0 1.057.423 1.726 1.269 2.008.845.282 2.237.37 4.176.265v7.189c-5.04.529-8.529.053-10.467-1.427-1.938-1.516-2.908-4.194-2.908-8.035v-9.913h-4.229v-7.613h4.229v-5.022l7.93-2.378v7.4h5.445z" />
            <path d="m79.928 45.011c-3.771 0-6.925-.811-9.463-2.432-2.502-1.656-4.281-3.894-5.339-6.714l7.295-4.229c1.481 3.419 4.071 5.128 7.771 5.128 3.348 0 5.022-1.004 5.022-3.013 0-1.093-.546-1.938-1.639-2.538-1.057-.634-3.066-1.356-6.026-2.167-1.551-.423-2.907-.899-4.07-1.427-1.163-.529-2.309-1.216-3.436-2.062-1.093-.881-1.939-1.973-2.538-3.277-.564-1.304-.846-2.802-.846-4.494 0-3.418 1.216-6.149 3.648-8.193 2.467-2.044 5.392-3.067 8.775-3.067 3.031 0 5.709.723 8.035 2.168 2.326 1.41 4.159 3.471 5.498 6.185l-7.136 4.176c-.67-1.41-1.498-2.467-2.485-3.172-.987-.74-2.291-1.11-3.912-1.11-1.269 0-2.255.282-2.96.846-.67.529-1.005 1.18-1.005 1.956 0 .916.423 1.709 1.269 2.379.881.669 2.679 1.409 5.392 2.22 1.48.458 2.626.828 3.436 1.11.811.282 1.833.758 3.066 1.427 1.269.635 2.22 1.322 2.855 2.062.669.705 1.251 1.656 1.744 2.855.529 1.163.793 2.502.793 4.017 0 3.524-1.268 6.308-3.806 8.352-2.537 2.009-5.85 3.014-9.938 3.014z" />
          </>
        )}
      </g>
    </svg>
  );
}
