import { Flex, Heading, Separator, Text } from "@radix-ui/themes";
import { Suspense, useCallback, useReducer } from "react";
import { useParams } from "react-router";
import { StoreID } from "services/data/data";
import { get, listAuditLog } from "services/schema";
import { useSchemaClient } from "services/schema/useSchemaClient";
import { useStoreInfo } from "state/user/hooks";
import { Store } from "state/user/types";
import { clear, suspend } from "suspend-react";
import ErrorBoundary from "ui/errors/ErrorBoundary";
import TerminalSnippet from "ui/shared/TerminalSnippet";
import AuditLogEntry from "./AuditLogEntry";
import SchemaContent from "./SchemaContent";

const schemaCache = (storeID: StoreID) => ["getSchema", storeID];
const auditLogCache = (storeID: StoreID) => ["listAuditLog", storeID];

export default function SchemaViewer() {
  const { storeId } = useParams<{ storeId: string }>();
  const storeID = BigInt(storeId ?? "0");
  const store = useStoreInfo(storeID);

  const [_reloadCount, triggerRender] = useReducer((x: number) => x + 1, 0);

  const onRollbackSuccess = useCallback(() => {
    clear(schemaCache(storeID));
    clear(auditLogCache(storeID));
    triggerRender();
  }, [storeID]);

  return (
    <Flex gap="4" direction="column" my="4">
      <Heading size="7" trim="end">
        Manage Schema
      </Heading>
      {!store ? (
        <Text>Store not found</Text>
      ) : (
        <Viewer storeID={storeID} store={store} onRollbackSuccess={onRollbackSuccess} />
      )}
    </Flex>
  );
}

export function Viewer({
  storeID,
  store,
  onRollbackSuccess,
}: {
  storeID: StoreID;
  store: Store;
  onRollbackSuccess: () => void;
}) {
  return (
    <>
      <Heading size="2" trim="end">
        {`Stores / ${store.name}`}
      </Heading>
      <Text size="1">Store ID: {storeID.toString()}</Text>

      <Separator size="4" />

      <Flex gap="4" direction="column">
        <Heading size="4" trim="end">
          Current Schema
        </Heading>
        <ErrorBoundary name="Schema">
          <Suspense fallback={<Text>Loading schema...</Text>}>
            <SchemaPanel storeID={storeID} />
          </Suspense>
        </ErrorBoundary>
      </Flex>

      <Separator size="4" />

      <Flex gap="4" direction="column">
        <Heading size="4" trim="end">
          Audit Log
        </Heading>
        <ErrorBoundary name="AuditLog">
          <Suspense fallback={<Text>Loading audit log...</Text>}>
            <AuditLogPanel storeID={storeID} onRollbackSuccess={onRollbackSuccess} />
          </Suspense>
        </ErrorBoundary>
      </Flex>
    </>
  );
}

function SchemaPanel({ storeID }: { storeID: StoreID }) {
  const client = useSchemaClient();
  const schema = suspend(() => get(client, storeID), schemaCache(storeID));

  if (!schema?.fileDescriptor) {
    return (
      <Text>
        No schema found for store. Upload one with{" "}
        <a href="https://stately.cloud/downloads">the Stately CLI</a>:
        <TerminalSnippet language="cli">
          {`stately schema put --store-id ${storeID.toString() ?? "store-id"} my-schema/index.ts`}
        </TerminalSnippet>
      </Text>
    );
  } else {
    return <SchemaContent schema={schema} />;
  }
}

function AuditLogPanel({
  storeID,
  onRollbackSuccess,
}: {
  storeID: StoreID;
  onRollbackSuccess: () => void;
}) {
  const client = useSchemaClient();
  const auditLog = suspend(() => listAuditLog(client, storeID), auditLogCache(storeID));

  if (auditLog.length === 0) {
    return <Text>No audit log found for store</Text>;
  } else {
    return auditLog.map((entry, i) => (
      <AuditLogEntry
        key={i}
        entry={entry}
        showRollbackButton={i > 0}
        onRollbackSuccess={onRollbackSuccess}
      />
    ));
  }
}
