import { FileDescriptorProto } from "@bufbuild/protobuf/wkt";
import { Heading } from "@radix-ui/themes";
import { SchemaModel } from "services/api/schemaservice/schema_pb";
import Mermaid from "./Mermaid";
import styles from "./SchemaContent.m.css";

/**
 * Pretty-printed version of the schema file descriptor.
 */
export default function SchemaContent({ schema }: { schema: SchemaModel }) {
  const mermaidDiagram = createMermaidDiagram(schema);

  return (
    <>
      {mermaidDiagram && (
        <>
          <Heading size="3">Objects</Heading>
          <Mermaid chart={mermaidDiagram} />
        </>
      )}
      {schema.formattedSchema && <KeyPaths formattedSchema={schema.formattedSchema} />}
      <Heading size="3">Schema</Heading>
      <pre className={styles.codeBox}>{schema.formattedSchema}</pre>
    </>
  );
}

function createMermaidDiagram({ fileDescriptor }: { fileDescriptor?: FileDescriptorProto }) {
  if (!fileDescriptor) {
    return "";
  }

  const messages = fileDescriptor.messageType || [];
  const enums = fileDescriptor.enumType || [];

  let diagram = "classDiagram\n";

  for (const message of messages) {
    diagram += `class ${message.name} {\n`;
    for (const field of message.field || []) {
      diagram += `  ${field.name}\n`;
    }
    diagram += "}\n";
  }
  for (const en of enums) {
    diagram += `class ${en.name} {\n`;
    for (const field of en.value || []) {
      diagram += `  ${field.name}\n`;
    }
    diagram += "}\n";
  }

  return diagram;
}

interface KeyPathTreeNode {
  [key: string]: KeyPathTreeNode | string;
}

function KeyPaths({ formattedSchema }: { formattedSchema: string }) {
  // Yes this is nasty but I'm being lazy
  const keyPaths: { [itemType: string]: string[] } = {};
  let itemType = "";
  for (const line of formattedSchema.split("\n")) {
    const itemTypeMatch = /itemType (\w+)/.exec(line);
    if (itemTypeMatch) {
      itemType = itemTypeMatch[1];
      continue;
    }

    const keyPathMatch = /Key Path: (.*)$/.exec(line);
    if (keyPathMatch) {
      (keyPaths[itemType] ||= []).push(keyPathMatch[1].replaceAll(/:[^/]+/g, "*"));
    }
  }

  const keyPathTree: KeyPathTreeNode = {};

  for (const [itemType, paths] of Object.entries(keyPaths)) {
    for (const keyPath of paths) {
      const parts = keyPath.split("/");
      parts.shift(); // Remove the leading empty string
      let node = keyPathTree;
      let i = 0;
      for (const part of parts) {
        if (i === parts.length - 1) {
          node[part] = { __itemType__: itemType };
        } else {
          node[part] ||= {};
          node = node[part] as KeyPathTreeNode;
        }
        i++;
      }
    }
  }

  return (
    <div>
      <Heading size="3">Key Path Structure</Heading>
      <KeyPathTreeNodeElem node={keyPathTree} />
    </div>
  );
}

function KeyPathTreeNodeElem({ node }: { node: KeyPathTreeNode }) {
  return (
    <ul>
      {Object.entries(node).map(([seg, value]) => (
        <li key={seg}>
          {seg === "__itemType__" ? (
            <strong>{value as string}</strong>
          ) : (
            <>
              {seg}:{" "}
              {typeof value === "string" ? <div>value</div> : <KeyPathTreeNodeElem node={value} />}
            </>
          )}
        </li>
      ))}
    </ul>
  );
}
