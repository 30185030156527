// @generated by protoc-gen-es v2.0.0 with parameter "target=js+dts,import_extension=.js"
// @generated from file user/add_users_to_organization.proto (package stately.user, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file user/add_users_to_organization.proto.
 */
export const file_user_add_users_to_organization =
  /*@__PURE__*/
  fileDesc(
    "CiR1c2VyL2FkZF91c2Vyc190b19vcmdhbml6YXRpb24ucHJvdG8SDHN0YXRlbHkudXNlciJKCh1BZGRVc2Vyc1RvT3JnYW5pemF0aW9uUmVxdWVzdBIXCg9vcmdhbml6YXRpb25faWQYASABKAQSEAoIdXNlcl9pZHMYAiADKAQiIAoeQWRkVXNlcnNUb09yZ2FuaXphdGlvblJlc3BvbnNlQoABChBjb20uc3RhdGVseS51c2VyQhtBZGRVc2Vyc1RvT3JnYW5pemF0aW9uUHJvdG9QAaICA1NVWKoCDFN0YXRlbHkuVXNlcsoCDFN0YXRlbHlcVXNlcuICGFN0YXRlbHlcVXNlclxHUEJNZXRhZGF0YeoCDVN0YXRlbHk6OlVzZXJiBnByb3RvMw",
  );

/**
 * Describes the message stately.user.AddUsersToOrganizationRequest.
 * Use `create(AddUsersToOrganizationRequestSchema)` to create a new message.
 */
export const AddUsersToOrganizationRequestSchema =
  /*@__PURE__*/
  messageDesc(file_user_add_users_to_organization, 0);

/**
 * Describes the message stately.user.AddUsersToOrganizationResponse.
 * Use `create(AddUsersToOrganizationResponseSchema)` to create a new message.
 */
export const AddUsersToOrganizationResponseSchema =
  /*@__PURE__*/
  messageDesc(file_user_add_users_to_organization, 1);
