// @generated by protoc-gen-es v2.0.0 with parameter "target=js+dts,import_extension=.js"
// @generated from file schemaservice/put.proto (package stately.schemaservice, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_google_protobuf_descriptor } from "@bufbuild/protobuf/wkt";
import { file_schemaservice_validate } from "./validate_pb.js";

/**
 * Describes the file schemaservice/put.proto.
 */
export const file_schemaservice_put =
  /*@__PURE__*/
  fileDesc(
    "ChdzY2hlbWFzZXJ2aWNlL3B1dC5wcm90bxIVc3RhdGVseS5zY2hlbWFzZXJ2aWNlIrABCgpQdXRSZXF1ZXN0EhAKCHN0b3JlX2lkGAEgASgEEj0KD2ZpbGVfZGVzY3JpcHRvchgCIAEoCzIkLmdvb2dsZS5wcm90b2J1Zi5GaWxlRGVzY3JpcHRvclByb3RvEg8KB2RyeV9ydW4YAyABKAgSGgoSY2hhbmdlX2Rlc2NyaXB0aW9uGAQgASgJEiQKHGFsbG93X2JhY2t3YXJkc19pbmNvbXBhdGlibGUYBSABKAgiZAoLUHV0UmVzcG9uc2USQgoRdmFsaWRhdGVfcmVzcG9uc2UYASABKAsyJy5zdGF0ZWx5LnNjaGVtYXNlcnZpY2UuVmFsaWRhdGVSZXNwb25zZRIRCgljb21taXR0ZWQYAiABKAhCmgEKGWNvbS5zdGF0ZWx5LnNjaGVtYXNlcnZpY2VCCFB1dFByb3RvUAGiAgNTU1iqAhVTdGF0ZWx5LlNjaGVtYXNlcnZpY2XKAhVTdGF0ZWx5XFNjaGVtYXNlcnZpY2XiAiFTdGF0ZWx5XFNjaGVtYXNlcnZpY2VcR1BCTWV0YWRhdGHqAhZTdGF0ZWx5OjpTY2hlbWFzZXJ2aWNlYgZwcm90bzM",
    [file_google_protobuf_descriptor, file_schemaservice_validate],
  );

/**
 * Describes the message stately.schemaservice.PutRequest.
 * Use `create(PutRequestSchema)` to create a new message.
 */
export const PutRequestSchema = /*@__PURE__*/ messageDesc(file_schemaservice_put, 0);

/**
 * Describes the message stately.schemaservice.PutResponse.
 * Use `create(PutResponseSchema)` to create a new message.
 */
export const PutResponseSchema = /*@__PURE__*/ messageDesc(file_schemaservice_put, 1);
