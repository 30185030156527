import { Component } from "react";
import { reportException } from "utils/sentry";
import ErrorPanel from "./ErrorPanel";

interface Props {
  name: string;
  children?: React.ReactNode;
}

interface State {
  error?: Error;
  eventId?: string;
}

/**
 * Error boundaries are like "catch" blocks - errors propagating up the
 * component tree stop here, and we show an error message instead of the
 * children of the error boundary. This has to be a class component because
 * React has no useErrorBoundary hook.
 */
export default class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidCatch(error: Error, errorInfo: { componentStack: string }) {
    const { name } = this.props;
    const eventId = reportException(name, error, errorInfo);
    this.setState({ error, eventId });
  }

  render() {
    const { error, eventId } = this.state;
    const { children } = this.props;

    if (error) {
      return <ErrorPanel error={error} eventId={eventId} />;
    }

    return children;
  }
}
